import React from 'react';
import { Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import Typography from '@material-ui/core/Typography';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ownUpLightTheme, OwnUpThemeProvider } from '@rategravity/own-up-component-library-new';
import { Helmet } from 'react-helmet';

export const wrapRootElement = ({ element }) => (
  <React.Fragment>
    <Helmet>
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
    </Helmet>
    <StylesProvider injectFirst>
      {/* Mui Theme Provider should onl be at the root*/}
      <MuiThemeProvider theme={ownUpLightTheme}>
        <OwnUpThemeProvider theme={ownUpLightTheme}>
          {/* Add the Mui CSS baseline to inject the fonts and body style. */}
          <CssBaseline />
          {/* Add the Mui Grid, Typography to inject the Grid and Typographyg root styling. */}
          <Grid />
          <Typography />
          {/* Use the scoped CSS baseline as well so that if there are conflicting
                styles on the body they will be asserted here */}
          <ScopedCssBaseline>{element}</ScopedCssBaseline>
        </OwnUpThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </React.Fragment>
);
