const topLevelScope = global || window;

if (topLevelScope.crypto) {
  module.exports = topLevelScope.crypto;
} else {
  //eslint-disable-next-line @typescript-eslint/no-var-requires
  const crypto = require('crypto');
  module.exports = {
    getRandomValues: (arr) => crypto.randomBytes(arr.length)
  };
}
