import { useTrackingInstance } from '@rategravity/1pt-lib';
import React, { ReactNode, useEffect } from 'react';

export const PageView = ({ children }: { children: ReactNode }) => {
  const { page } = useTrackingInstance();
  useEffect(() => {
    page();
  });
  return <>{children}</>;
};
