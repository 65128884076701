import React from 'react';
import { breakpoints } from './src/modules/breakpoints';
import { OwnUpThemeWrapper } from '@rategravity/own-up-component-library';
import {
  OwnUpComponentLibraryProvider,
  ownUpLightTheme
} from '@rategravity/own-up-component-library-new';
import { createTrackingInstance, TrackingProvider } from '@rategravity/1pt-lib';

const App = ({ element }) => {
  const trackingInstance = createTrackingInstance({
    url: '/events'
  });
  return (
    <OwnUpComponentLibraryProvider theme={ownUpLightTheme}>
      <OwnUpThemeWrapper breakpoints={breakpoints} preload_advisors={false}>
        <TrackingProvider value={trackingInstance}>{element}</TrackingProvider>
      </OwnUpThemeWrapper>
    </OwnUpComponentLibraryProvider>
  );
};

export default ({ element }) => <App element={element} />;
